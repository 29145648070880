import 'core-js/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import VideoPlayerWrapper from './video-player-wrapper'

const root = createRoot(document.getElementById('root'))

root.render(
  <VideoPlayerWrapper
    accountName={window.bootstrapData.accountName}
    analyticsDomain={window.bootstrapData.analyticsDomain}
    assetExternalId={window.bootstrapData.assetExternalId}
    assetVersionUUID={window.bootstrapData.assetVersionUUID}
    autoplay={window.bootstrapData.autoplay}
    captions={window.bootstrapData.previews.captions}
    downloadUrl={window.bootstrapData.downloadUrl}
    isShareLink={window.bootstrapData.isShareLink}
    muted={window.bootstrapData.muted}
    poster={window.bootstrapData.previews.poster}
    referer={window.bootstrapData.referer}
    source={
      window.bootstrapData.previews.hls || window.bootstrapData.previews.files
    }
    video360={window.bootstrapData.video360}
    videojs={window.videojs}
  />
)
