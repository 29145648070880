import React from 'react'
import { WidenGlobalStyles } from '@widen/patterns-style'
import { VideoPlayer } from '@widen/patterns-video-player-react'

export default class VideoPlayerWrapper extends React.Component {
  playerInstance = null
  sessionId = generateSessionId()

  state = {
    isPlayerReady: false,
    playingInterval: null,
  }

  componentDidUpdate(_, prevState) {
    if (this.state.playingInterval !== prevState.playingInterval) {
      prevState.playingInterval && clearInterval(prevState.playingInterval)
    }
  }

  componentWillUnmount() {
    this.state.playingInterval && clearInterval(this.state.playingInterval)
  }

  render() {
    return (
      <React.Fragment>
        <WidenGlobalStyles />
        <VideoPlayer
          autoplay={this.props.autoplay}
          captions={this.props.captions}
          innerRef={(el) => (this.playerInstance = el)}
          muted={this.props.muted}
          on={{
            ended: this._onEnded,
            play: this._onPlay,
            ready: this._onReady,
          }}
          options={{
            autoMobileFullscreen: false,
            autoplayNext: false,
            downloadUrl: this.props.downloadUrl,
            fullWidth: true,
            video360: this.props.video360,
          }}
          poster={this.props.poster}
          source={this.props.source}
        />
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img height="0" id="player-ping" width="0" />
      </React.Fragment>
    )
  }

  _onEnded = () => {
    this.setState({ playingInterval: null })
    this._postEvent('complete')
  }

  _onReady = () => {
    this.setState({ isPlayerReady: true })
    this._postEvent('load')
  }

  _onPlay = () => {
    if (this.playerInstance._player.currentTime() === 0) {
      this._postEvent('play')
    }
    if (this.state.playingInterval) {
      return
    }
    this.setState({
      playingInterval: setInterval(() => {
        if (this.playerInstance && !this.playerInstance._player.paused()) {
          this._postEvent('playing')
        }
      }, 5000),
    })
  }

  _postEvent(event) {
    // prettier-ignore
    document.getElementById("player-ping").src= "https://" + this.props.analyticsDomain + "/ping.png" +
      "?session=" + this.sessionId +
      "&client=" + this.props.accountName +
      "&resource=" + (this.props.assetExternalId ? this.props.assetExternalId : this.props.assetVersionUUID) +
      "&event=" + event +
      "&played=" + (this.playerInstance ? this.playerInstance._player.currentTime() : '') +
      "&location=" + getLocation(this.props.isShareLink) +
      "&referer=" + (this.props.referer == null ? '' : this.props.referer);
  }
}

function generateSessionId() {
  let d = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16)
    }
  )
  return uuid
}

function getLocation(shareLink) {
  if (window.parent !== window) {
    if (location.href.match(/&signature=/i)) {
      return 'DAM'
    }

    if (document.referrer.match(/\/[a-z0-9]{6}(\/[^/]+)?$/i)) {
      return 'SHARE_LINK'
    }
  }

  if (shareLink || location.search.match(/\bx\.share=(t|true|yes)\b/)) {
    return 'SHARE_LINK'
  }

  return 'EMBED'
}
